import React from "react";

import scrollTo from "gatsby-plugin-smoothscroll";

import Hero from "../../images/Hero.jpg";

import * as styles from "./Main.module.css";

const Main = () => {
  return (
    <section className={styles.container} id="main">
      <div className={styles.hero}>
        <img src={Hero} alt="People taking Interview" />
      </div>
      <div className={styles.content}>
        <h1>Hire to Retire</h1>
        <p className={styles.tagLine}>
          Employee Lifecycle management for Smarter Workforce
        </p>
        <p>Adapt to future HR innovations through configurable workflows</p>
        <button onClick={() => scrollTo("#contact", "center")}>
          Get in Touch
        </button>
      </div>
    </section>
  );
};

export default Main;
