import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Main from "../components/Main/Main";
import About from "../components/About/About";

import "../styles/global.css";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Alchemus</title>
        <meta
          name="description"
          content="Employee Lifecycle management for Smarter Workforce. Adapt to future HR innovations through configurable workflows"
        />
      </Helmet>
      <main>
        <Navbar />
        <Main />
        <About />
        <Footer />
      </main>
    </>
  );
};

export default Index;
