import React from "react";
import { Link } from "gatsby";

import Logo from "../../images/logo.png";

import * as styles from "./Navbar.module.css";

const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={Logo} alt="Alchemus Logo" />
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
