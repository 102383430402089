import React from "react";

import Contact from "../Contact/Contact";

import wave from "../../images/wave.png";

import * as styles from "./Footer.module.css";

const Footer = () => {
  return (
    <>
      <div className={styles.wave}>
        <img src={wave} alt="Wave" />
      </div>
      <footer className={styles.container}>
        <div className={styles.innerContainer}>
          <Contact />
          <div>
            <h3>Address</h3>
            <p>C103, Mangalaya Towers, Andheri(E), Marol Mumbai-400061</p>
          </div>
          <div className={styles.contacts}>
            <div>
              <h3>Phone</h3>
              <a href="tel:+917208473570">+91 72084 73570</a>
            </div>
            <div>
              <h3>Whatsapp</h3>
              <a href="https://wa.me/+917208473570">+91 72084 73570</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
