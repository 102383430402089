import React from "react";

import * as styles from "./Contact.module.css";

const Contact = () => {
  return (
    <section id="contact" className={styles.container}>
      <h2>Contact us</h2>
      <form className={styles.form}>
        <label>
          Name
          <input type="text" id="name" name="name" />
        </label>
        <label>
          Email
          <input type="text" id="email" name="email" />
        </label>
        <label>
          Message
          <textarea id="message" name="message" />
        </label>
        <input className={styles.btn} type="submit" id="submit" />
      </form>
    </section>
  );
};

export default Contact;
