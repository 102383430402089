import React from "react";

import Empower from "../../images/Empower.png";
import Transparent from "../../images/Transparent.png";
import Ensure from "../../images/Ensure.png";
import DataDrive from "../../images/Data-Drive.png";

import * as styles from "./About.module.css";

const About = () => {
  return (
    <section id="about" className={styles.container}>
      <div className={styles.intro}>
        <h2>Tailor-made features</h2>
        <p>
          Lorem ipsum is common placeholder text used to demonstrate the graphic
          elements of a document or visual presentation.
        </p>
      </div>

      <div className={styles.features}>
        <div className={styles.feature}>
          <div className={styles.featureLogo}>
            <img src={Empower} alt="Gear and Wrench logo" />
          </div>
          <h3>
            Empower your global workforce with the right tools & Methodologies
          </h3>
          <p>
            Move to a seamless HR experience with KPIs/OKRs that can measure
            performance as per company goals & industry standards. Adapt better
            to the changing talent needs and ensure employees hone the skills
            needed in future.
          </p>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureLogo}>
            <img src={Transparent} alt="Recycle logo" />
          </div>
          <h3>Transparent workflows across the HR ecosystem</h3>
          <p>
            Support the global workforce by enhancing HR service delivery
            through connected benefits, payroll and attendance, performance
            management, compliances, along with other core HR needs.
          </p>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureLogo}>
            <img src={Ensure} alt="Stack of logo" />
          </div>
          <h3>Ensure quicker adaption to the changing talent lifecycle</h3>
          <p>
            Attract ideal candidates and make faster hiring decisions through
            augmented analytics. Ensure seamless onboarding and develop adequate
            succession planning. Manage customized compensation planning for
            your global workforce.
          </p>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureLogo}>
            <img src={DataDrive} alt="Data Base logo" />
          </div>
          <h3>Use data-driven insights for smarter decision-making</h3>
          <p>
            A solution that can unify the data across all modules, giving
            actionable insights into each metric. Augmented analytics can allow
            senior management to make smarter decisions and unleash workforce
            capabilities.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
